import React from "react";
import { Icon } from "@iconify/react";
import "../css/whatsappButton.css";
const WhatsappButton = ({ phoneNumber, message }) => {
  const handleClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <button className="whatsapp-button" onClick={handleClick}>
      <Icon icon="logos:whatsapp-icon" />
    </button>
  );
};

export default WhatsappButton;

import React from "react";
import "../css/footer.css";
import logo_Blanco from "../img/logo_Blanco.svg";
import { Icon } from "@iconify/react";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={logo_Blanco} alt="logo-Panamerine repair" />
        <p>#SafetyFirst</p>
      </div>
      <div className="footer-redes">
        <p>Follow us</p>
        <div>
          <a href="https://walink.co/2ced49" target="_blank">
            <Icon icon="ic:baseline-whatsapp" id="icon-redes" />
          </a>
          <a
            href="https://www.instagram.com/panamarinerepairpty/?hl=es"
            target="_blank"
          >
            <Icon icon="mdi:instagram" id="icon-redes" />
          </a>
          <a href="" target="_blank">
            <Icon icon="ri:facebook-fill" id="icon-redes" />
          </a>
          <a href="" target="_blank">
            <Icon icon="ic:baseline-tiktok" id="icon-redes" />
          </a>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "../css/about.css";
import mejores_practicas from "../img/mejores_practicas.png";
import proteger from "../img/proteger.png";
import innovacion from "../img/innovacion.png";
import charla from "../img/charla.png";
import video from "../img/video.mp4";
export default function About() {
  return (
    <section className="about">
      <h1>
        About <span>us</span>
      </h1>
      <h1 className="title-mission">
        Our <span>misssion</span>
      </h1>
      <p className="text-about">
        At PANAMARINE REPAIR, our mission is to provide yacht owners and
        enthusiasts with unparalleled yacht repair and maintenance services that
        blend technical expertise and innovation, we are dedicated to:
      </p>
      <div className="mission-text">
        <div className="mission-card">
          <img src={mejores_practicas} alt="icon" />
          <div className="text-mission-card">
            <h3>Excellence in Craftsmanship: </h3>
            <p>
              Delivering top-tier repair, restoration, and maintenance services
              that ensure the safety, functionality, and aesthetics of every
              yacht we work on.
            </p>
          </div>
        </div>
        <div className="mission-card">
          <img src={charla} alt="icon" />
          <div className="text-mission-card">
            <h3>Customer Satisfaction: </h3>
            <p>
              Placing our customers at the heart of everything we do, and
              striving to exceed their expectations by providing exceptional
              service, transparent communication, and a personalized experience.
            </p>
          </div>
        </div>
        <div className="mission-card">
          <img src={innovacion} alt="icon" />
          <div className="text-mission-card">
            <h3>Innovation and Advancement: </h3>
            <p>
              Continuously seeking out and implementing cutting-edge
              technologies and techniques to enhance the performance and
              longevity of yachts.
            </p>
          </div>
        </div>
        <div className="mission-card">
          <img src={proteger} alt="icon" />
          <div className="text-mission-card">
            <h3>Safety First: </h3>
            <p>
              Prioritizing the safety of both our clients and our team members,
              adhering to the highest industry standards and best practices.
            </p>
          </div>
        </div>
      </div>

      <div className="vision">
        <div className="vision-text">
          <div>
            <h1>
              Our <span>vision</span>
            </h1>
            <p>
              We envision ourselves as undisputed leaders in the global yacht
              repair industry, recognized for our technical excellence,
              exceptional customer care, and unwavering commitment to preserving
              the beauty and functionality of personal yachts and commercials
              vessels. Our vision is to be the first choice for yacht owners
              worldwide, providing repair and maintenance services that surpass
              all expectations, implementing advanced technologies and
              eco-friendly processes to enhance the performance and durability
              of every vessel we touch.
            </p>
          </div>

          {/*<video controls autoPlay loop muted>
            <source src={video} type="video/mp4" />
            Tu navegador no soporta el tag de video.
            </video>*/}
        </div>
        <div className="vision-card">
          <p>
            This vision reflects a deep commitment to quality, sustainability,
            and customer satisfaction while aiming to establish the company as a
            global leader in the yacht repair industry. It also emphasizes the
            importance of technological innovation and environmental respect,
            both critical aspects in today's maritime industry.
          </p>
        </div>
      </div>
    </section>
  );
}

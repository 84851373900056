import "../css/contact.css";
import { Icon } from "@iconify/react";
import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";

export default function Contact() {
  const form = useRef();
  const [message, setMessage] = useState(null);

  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //Emailjs

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3apcj58",
        "panamerineRepair",
        form.current,
        "w0s0Nuzv3My2PXUWs"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessage(
            "Thank you for writing to us! Our team will contact you soon."
          );
          form.current.reset();
          openModal();
        },
        (error) => {
          console.log(error.text);
          setMessage(
            "There was an error sending the message. Please try again later."
          );
        }
      );
  };
  return (
    <section className="contact">
      <div className="contact-sections">
        <div className="information">
          <h2>Get in touch</h2>
          <h3>Chat with us</h3>
          <p>Our friendly team is here to help you</p>
          <p>panamarinerepair@outlook.com</p>
          <p>(+507) 6232 8629</p>
          <h3>Contact us</h3>
          <p>Mon-Fri from 8 am to 5pm - EST</p>
          <p>(+507) 6232 8629</p>
          <h3>Social</h3>
          <p>Follow us</p>
          <div className="social-contact">
            <a href="https://walink.co/2ced49" target="_blank">
              <Icon icon="basil:whatsapp-outline" id="icon-contact" />
            </a>
            <a
              href="https://www.instagram.com/panamarinerepairpty/?hl=es"
              target="_blank"
            >
              <Icon icon="mdi:instagram" id="icon-contact" />
            </a>
            <a href="" target="_blank">
              <Icon icon="ri:facebook-fill" id="icon-contact" />
            </a>
          </div>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <h1>Become a customer</h1>
          <div className="form-dual">
            <div className="casilla">
              <label>Firts name</label>
              <input name="firstName" type="text" required></input>
            </div>
            <div div className="casilla" id="space-input">
              <label>Last name</label>
              <input name="lastName" type="text" required />
            </div>
          </div>
          <div div className="casilla">
            <label>Boat or company name</label>
            <input name="companyName" type="text" />
          </div>
          <div div className="casilla">
            <label>Email</label>
            <input type="email" name="email" required />
          </div>
          <div div className="casilla">
            <label>Phone</label>
            <input type="phone" name="phone" required />
          </div>
          <div div className="casilla">
            <h2>Message</h2>
            <label>Tell us what we can help you with</label>
            <textarea type="text" id="fname" name="message" />
          </div>
          <input className="btn" type="submit" value="Send message" />
          {isModalOpen && (
            <div className="overlay">
              <div className="modal">
                <div className="modal-content">
                  <p>{message}</p>
                  <button className="btn" onClick={closeModal}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </section>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import CarouselTestimonials from "../components/CarouselTestimonials";
import "../css/home.css";
import background_home from "../img/background_home.png";
import bg_testimonials from "../img/bg_testimonials.png";
import background_testimonials from "../img/background_testimonials.png";
import boat_viking from "../img/boat_viking.png";
import gary from "../img/gary.png";
import SliderBrands from "../components/SlideBrands";

export default function Home() {
  return (
    <section className="home">
      <div
        style={{
          backgroundImage: `url(${background_home})`,
        }}
        className="principal"
      >
        <div className="img-gary">
          <img src={gary} alt="Gary-photo" />
          <div className="gary">
            <h2>Gary A. Nichols</h2>
            <p>Naval engineer</p>
          </div>
        </div>

        <div className="text-principal">
          <h1>The best service for your boat</h1>
          <p>work with the experts</p>
          <h1 className="name-panamarine">
            PANA<span>M</span>ARINE REPAIR
          </h1>
        </div>
      </div>
      <div className="home-services">
        <h1>
          We provide a broad <span>range of specialized services</span> for your
          boat,
        </h1>
        <div className="text-home-services">
          <div className="p-services">
            <p>
              Including diesel engine repairs, transmissions, performance
              upgrades, and more. Our experts ensure your boat is in top
              condition for a perfect boating experience.
            </p>
            <Link to="/services" id="hola" className="btn">
              View all services
            </Link>
          </div>
          <img src={boat_viking} alt="bote-img" className="img-home-services" />
        </div>
      </div>

      <div className="testimonials">
        <div className="top-testimonials"></div>
        <h1>
          Get to <span>know our work</span>
        </h1>
        <CarouselTestimonials />
      </div>
      <div className="bottom-testimonials"></div>
      <div className="button-work">
        <Link to="/ourWork" className="btn" id="btn-testimonials">
          Know our work
        </Link>
      </div>

      <div className="brands">
        <h1>
          We work with the best <span>brands services</span>
        </h1>
        <SliderBrands />
      </div>
    </section>
  );
}

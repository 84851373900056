import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "../css/header.css";
import logo_colors from "../img/logo_colors.svg";
import logo_Blanco from "../img/logo_Blanco.svg";

export default function Header() {
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const isActive = (path) => location.pathname === path;

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <section>
      <div
        className={`menu ${isActive("/") ? "home" : ""} ${
          isActive("/ourWork") ? "our-work" : ""
        }`}
      >
        {isActive("/") || isActive("/ourWork") ? (
          <img src={logo_Blanco} alt="Logo_PanamarineRepair" />
        ) : (
          <img src={logo_colors} alt="Logo_PanamarineRepair" />
        )}
        <div
          className={`hamburguer-menu ${isActive("/") ? "home" : ""} ${
            isActive("/ourWork") ? "our-work" : ""
          }`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav className={`menu-links ${isMenuOpen ? "open" : ""}`}>
          <Link
            to="/"
            className={`links-menu ${isActive("/") ? "home" : ""} ${
              isActive("/ourWork") ? "our-work" : ""
            }`}
            style={{ color: isActive("/") ? "#14F8AB" : "" }}
          >
            Home
          </Link>
          <Link
            to="/services"
            className={`links-menu ${isActive("/") ? "home" : ""} ${
              isActive("/ourWork") ? "our-work" : ""
            }`}
            style={{ color: isActive("/services") ? "#18BAF7" : "" }}
          >
            Services
          </Link>
          <Link
            to="/ourWork"
            className={`links-menu ${isActive("/") ? "home" : ""} ${
              isActive("/ourWork") ? "our-work" : ""
            }`}
            style={{ color: isActive("/ourWork") ? "#14F8AB" : "" }}
          >
            Our work
          </Link>
          <Link
            to="/about"
            className={`links-menu ${isActive("/") ? "home" : ""} ${
              isActive("/ourWork") ? "our-work" : ""
            }`}
            style={{ color: isActive("/about") ? "#18BAF7" : "" }}
          >
            About us
          </Link>
          <Link
            to="/contact"
            className={`links-menu ${isActive("/") ? "home" : ""} ${
              isActive("/ourWork") ? "our-work" : ""
            }`}
            style={{ color: isActive("/contact") ? "#18BAF7" : "" }}
          >
            Contact
          </Link>
        </nav>
      </div>
      <Outlet />
    </section>
  );
}
